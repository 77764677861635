import React, { Props } from "react"
import { Collapse, Input, Form, Icon, Button, Spin, InputNumber, Modal, Row, Col } from 'antd'
import { Helmet } from "react-helmet"
import SiteLayout from "../components/SiteLayout";
import axios from 'axios';
import Img from "gatsby-image";
import { StaticQuery, graphql } from 'gatsby';
import AppStoreButton from "../components/Buttons/AppStoreButton";
import GooglePlayButton from "../components/Buttons/GooglePlayButton";

const Panel = Collapse.Panel;

enum Page {
    Loading = 0,
    Confirm = 1,
    ThankYou = 2,
    NoThankYou = 3,
    Error = 4,
    PostError = 5,
}

// @ts-ignore
import styles from './if_pilot.module.scss';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const API_URL = process.env.GATSBY_APP_API_URL;
const API_TOKEN = process.env.GATSBY_APP_API_TOKEN;

class BekreftForm extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            confirming: false,
            rejecting: false,
            buttonsDisabled: true,
            fieldsActive: true,
            page: Page.Loading,
            confirmationId: null,
            confirmationRequest: null,
            customerNumber: ""
        };

        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.lockForm = this.lockForm.bind(this);
        this.unlockForm = this.unlockForm.bind(this);
        this.handleEmptyValues = this.handleEmptyValues.bind(this);


    }

    componentDidMount() {
        /* const urlParams = new URLSearchParams(window.location.search);
        const customerNumber = urlParams.get('k');
        if (customerNumber !== null) {
            this.getLead(customerNumber);
            this.setState({ customerNumber: customerNumber });
        }
        else {
           this.setState({ page: Page.Error }); 
        } */
    }

    getLead(customerNumber: string) {
        var config = {
            headers: {'Authorization': "bearer " + API_TOKEN}
        };
        axios.get(API_URL + "/api/ifLeads?customerNumber=" + customerNumber, config).then(res => {
            if (res.status == 200) {             
                this.setState({ page: Page.Confirm, confirmationRequest: res.data });
            }
        }).catch(err => {
            this.setState({ page: Page.Error });
            //console.log(err);
        })
    }

    onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
        let fieldName = e.target.name;
        let value = e.target.value;

        const nextState = {
            ...this.state.confirmationRequest,
            [fieldName]: value,
        };
        this.setState({ confirmationRequest: nextState });
        this.handleEmptyValues(value);
    }

    handleEmptyValues(value: string) {
        // This is hacky but I don't want to spend more time on it and it seems to work...
        setTimeout(function () {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.setState({ buttonsDisabled: false });
                    
                } else {
                    this.setState({ buttonsDisabled: true });
                }
            });
        }.bind(this), 200);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.lockForm(true);

        if (this.state.confirmationRequest.email !== this.state.confirmationRequest.epost) {
            console.log("Emails do not match");
            console.log("email: " + this.state.confirmationRequest.email + " epost: " + this.state.confirmationRequest.bekreftEpost)
            this.unlockForm();
        }

        var config = {
            headers: {'Authorization': "bearer " + API_TOKEN}
        };
        var body = { 
            email: this.state.confirmationRequest.email,
            firstName: this.state.confirmationRequest.firstName,
            lastName: this.state.confirmationRequest.lastName,
            phoneNumber: this.state.confirmationRequest.phoneNumber,
            customerNumber: this.state.customerNumber,
            streetAddress: this.state.confirmationRequest.streetAddress,
            postCode: this.state.confirmationRequest.postCode,
            postalArea: this.state.confirmationRequest.postalArea,
            meterNumber: this.state.confirmationRequest.meterNumber,
        }
        axios.post(API_URL + "/api/ifLeads", body, config).then(res => {
            if (res.status >= 200 && res.status < 300) {
                this.setState({ page: Page.ThankYou });
            }
            else {
                this.setState({ page: Page.PostError });
            }
        }).catch(err => {
            this.setState({ page: Page.PostError });
        })
    }


    lockForm(confirming: boolean) {
        this.setState({ buttonsDisabled: true, fieldsActive: false, confirming: confirming, rejecting: !confirming });
    }

    unlockForm() {
        this.setState({ buttonsDisabled: false, fieldActive: true, confirming: false, rejecting: false });
    }


    renderConfirmPage() {
        const { getFieldDecorator } = this.props.form;

        return (
            <StaticQuery
                query={graphql`
                {
                    pitch: file(relativePath: {eq: "homescreen.png"}) {
                        childImageSharp{
                        fluid(maxWidth: 680) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                    }
                }
            `
                }
                render={data => (
                    <div style={{textAlign: 'center'}} >
                    <img width="100" src="https://if-vid-brand-cdn.azureedge.net/images/logo/logo.svg"/><br/><br/><br/>
                        <h2  style={{textAlign: 'center'}}>Bli med i piloten</h2> 
                        <div className={styles.introsubtext}>
                            Det er veldig gøy at du er interessert i å være med i pilotprosjektet mellom If og Oss Norge. Ved å være med i piloten hjelper du oss å undersøke hvordan vi kan bidra til å redusere skader og trygge boligen din. Samtidig får du tilgang til Oss sine tjenester, helt kostnadsfritt.  
                        </div>
                      
                        <div className={styles.introsubtext}>
                            <i>For få tilgang til tjenestene er vi avhengig av litt informasjon fra deg. Vennligst ta en titt på informasjonen vi har registrert, fyll inn feltene som mangler og korriger om nødvendig.</i>
                        </div> 
                        <div className={styles.introtext}>
                            <Form hideRequiredMark={true} onSubmit={this.handleSubmit} className={styles.confirmform} layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <Form.Item className={styles.compactfield} label="Fornavn">
                                    {getFieldDecorator('firstname', {
                                        rules: [{ required: true, message: 'Du må fylle inn fornavn' }],
                                        initialValue: this.state.confirmationRequest.firstName
                                    })(
                                        <Input autoComplete="off" disabled={!this.state.fieldsActive} name="firstname" size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Fornavn" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    label="Etternavn"
                                >
                                    {getFieldDecorator('lastname', {
                                        rules: [{ required: true, message: 'Du må fylle inn etternavn' }],
                                        initialValue: this.state.confirmationRequest.lastName
                                    })(
                                        <Input disabled={!this.state.fieldsActive} name="lastname" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Etternavn" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Epost"
                                >
                                    {getFieldDecorator('epost', {
                                        rules: [{ required: true, message: 'Du må fylle inn epost' }],
                                        initialValue: this.state.confirmationRequest.epost
                                    })(
                                        <Input disabled={!this.state.fieldsActive} name="email" size="large" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Epost" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Bekreft Epost"
                                >
                                    {getFieldDecorator('bekreftEpost', {
                                        rules: [{ required: true, message: 'Du må fylle inn epost igjen' }],
                                        initialValue: this.state.confirmationRequest.email
                                    })(
                                        <Input disabled={!this.state.fieldsActive} name="bekreftEpost" size="large" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Bekreft Epost" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Telefonnummer"
                                >
                                    {getFieldDecorator('phoneNumber', {
                                        rules: [{ required: true, message: 'Du må fylle inn telefonnummer' }],
                                        initialValue: this.state.confirmationRequest.phoneNumber
                                    })(
                                        <Input disabled={!this.state.fieldsActive} name="phoneNumber" size="large" prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Telefonnummer" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>


                                <Form.Item className={styles.compactfield}
                                    label="Addresse"
                                >
                                    {getFieldDecorator('streetAddress1', {
                                        rules: [{ required: true, message: 'Du må fylle inn adresse' }],
                                        initialValue: this.state.confirmationRequest.streetAddress
                                    })(
                                        <Input disabled={!this.state.fieldsActive} name="streetAddress1" size="large" prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Gateadresse" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>

                                <Form.Item className={styles.compactfield}
                                    label="Postnummer"
                                >
                                    {getFieldDecorator('postCode', {
                                        rules: [{ required: true, message: 'Du må fylle inn gyldig postnummer', min: 4, max: 4 }],
                                        initialValue: this.state.confirmationRequest.postCode
                                    })(
                                        <Input type="number" disabled={!this.state.fieldsActive} name="postCode" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Postnummer" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    required
                                    help=""
                                    label="Sted"
                                >
                                    {getFieldDecorator('sted', {
                                        rules: [{ required: true, message: 'Du må fylle inn sted' }],
                                        initialValue: this.state.confirmationRequest.postalArea
                                    })(
                                        <Input disabled={!this.state.fieldsActive} name="postalArea" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Stedsnavn"  />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Målernummer"
                                >
                                    {getFieldDecorator('malerNummerNumber', {
                                        rules: [{ required: true, message: 'Du må fylle inn målernummer' }],
                                        initialValue: this.state.confirmationRequest.meterNumber
                                    })(
                                        <Input disabled name="malerNummer" size="large" prefix={<Icon type="cluster" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Målernummer" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>
                                <br />
                                <div className={styles.introsubtext}>Ved å bli med i denne piloten samtykker du til å dele dine strømdata med If og at Oss kan ha dialog med deg, samt akseptere <a target="_blank" href="/brukervilkar_ifPilot"  className={styles.reservationLink}> brukervilkårene </a></div>
                                <Button loading={this.state.confirming} type="primary" htmlType="submit" className={styles.confirmbutton} size="large" disabled={this.state.buttonsDisabled}>
                                    Ok!
                                </Button>

                                
                            </Form>
                        </div>
                        <Row className={styles.container}>
                            <Col xs={24} sm={24} lg={24}>
                                <Row type="flex" align="top" gutter={24}>
                                    {/* <Col xs={24} sm={12} lg={10}> */}
                                        {/* <div className={styles.subheader}> */}
                                            <h1>Med Oss får du...</h1>
                                        {/* </div> */}
                                    {/* </Col> */}
                                    {/* <Col xs={24} sm={12} lg={14}>
                                        <div className={styles.imagecontainer}>
                                            <Img title="Homescreen" fluid={data.pitch.childImageSharp.fluid} className={styles.image} alt="Oss-appen homescreen" />
                                        </div>
                                    </Col> */}
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} lg={24}>
                                        <div className={styles.subheader_under}>
                                            Se strømforbruket ditt akkurat nå
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                            Hvor mye strøm bruker du akkurat nå? For folk flest er det helt gresk. Dette gir Oss deg muligheten til å se nå.  Kanskje klarer du ved hjelp av appen å finne strømtyvene i hjemmet ditt?
                                            
                                        </div>
                                        
                                        <div className={styles.subheader_under}>
                                            Se ditt historiske forbruk
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                            Spol tilbake i tid og se ditt historiske strømforbruk. Denne tjenesten gir deg muligheten til å sammenligne forbruket ditt på timer, dager, uker, måneder og år.
                                        </div>
                                        <div className={styles.subheader_under}>
                                            Sammenlign forbruket ditt med tilsvarende hjem
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                            Bruker jeg mye eller lite strøm lurer mange på. Få et forhold til hvordan strømforbruket ditt er sammenlignet med tilsvarende hjem. Er du en bedre strømforbruker enn naboen?
                                        </div>
                                        <div className={styles.subheader_under}>
                                            Prediksjon av forbruk
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                            Med våre smarte algoritmer kan vi fortelle deg hvor mye strøm du mest sannsynlig kommer til å bruke i morgen.
                                        </div>
                                        <div className={styles.subheader_under}>
                                            Smarte varslinger
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                            Er strømforbruket ditt høyere enn normalt eller har strømprisen gått betydelig opp i Norge, så gir vi deg beskjed (bare hvis du selv ønsker så klart).
                                        </div>
                                        <div className={styles.subheader_under}>
                                            Legg inn din strømavtale
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                            Hvis du legger inn strømavtalen din kan du følge med på hvor høy strømregningen blir, slik at du slipper en vond overraskelse på slutten av måneden.
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
            />
        )
    }

    renderLoadingPage() {
        return (
            <div>
                <div className={styles.introtext}>Tusen takk for din interesse. Vi tar dessverre ikke imot flere registreringer, da vi har nådd antall deltagere vi trenger i pilotprosjektet mellom If og Oss.</div>
            </div>
        )
    }

    renderPostErrorPage() {
        return (
            <div>
                <h1>Noe gikk galt</h1>
                <div className={styles.introtext}>Noe gikk galt da vi prøvde å lagre bekreftelsen din. Prøv på nytt, og ta kontakt med Oss på <a href="mailto:hei@oss.no">hei@oss.no</a> om problemet vedvarer.<br /><br /></div>
            </div>
        )
    }


    renderThankYouPage() {
        window.scrollTo(0, 0);
        return (
            <div>
                <h1>Tusen takk for din registrering</h1>
                <div className={styles.introtext}>
                    Du er nå med i pilotprosjektet, og vil om ikke lenge få en Oss-brikke. Brikken vil bli sendt fra Oss om 5-7 dager. Her kommer litt praktisk informasjon for å komme i gang. 
                </div>
                <div className={styles.subheader}>
                    Åpne HAN-porten på strømmåleren din
                </div>
                <div className={styles.text}>
                    Det første du må gjøre er å kontakte din nettleverandør (ikke strømleverandør), og be de om å åpne HAN-porten din. Hos de aller fleste nettleverandører kan dette gjøres via Min Side. Er ikke dette tilfellet hos deg, kontakt din nettleverandør via e-post eller telefon. 
                </div>
                <div className={styles.subheader}>
                    Last ned Oss-appen
                </div>
                <div className={styles.text}>
                    Neste steg er å laste ned Oss-appen, som du finner her:
                    <Row style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", maxWidth: 330 }} type="flex" justify="space-around" align="middle">
                        <Col xs={24} sm={12} style={{ marginTop: 24 }}><AppStoreButton /></Col>
                        <Col xs={24} sm={12} style={{ marginTop: 24 }}><GooglePlayButton /></Col>
                    </Row>
                </div>
                <div className={styles.subheader}>
                    Logge inn i Oss-appen
                </div>
                <div className={styles.text}>
                    Når du har lastet ned appen velger du "Til innlogging, er du grei". Du legger inn e-post adressen du brukte ved registrering, og vi sender deg en påloggingskode på mail (vær obs på at denne kan komme i søppelpost). Nå er alt klart, og du vil motta en bekreftelse på e-post. Så fort Oss-brikken er på plass vil du få tilgang til alle funksjoner i appen.
                </div>
            </div>
        )
    }


    renderErrorPage() {
        window.scrollTo(0, 0);
        return (
            <div>
                <h1>Ugyldig lenke</h1>
                <div className={styles.introtext}>Vi kan ikke finne deg i systemet vårt, husk å bruke lenken som ligger i mailen din. Ta kontakt med oss dersom du mener dette er feil. <a href="mailto:hei@oss.no">hei@oss.no</a> eller tekstboblen nede i høyre hjørne.</div>
            </div>
        )
    }

    renderPage() {
        if (this.state.page == Page.Loading) {
            return this.renderLoadingPage();
        }
        if (this.state.page == Page.Confirm) {
            return this.renderConfirmPage();
        }
        if (this.state.page == Page.ThankYou) {
            return this.renderThankYouPage();
        }
        if (this.state.page == Page.Error) {
            return this.renderErrorPage();
            //return this.renderConfirmPage();
        }
        if (this.state.page == Page.PostError) {
            return this.renderPostErrorPage();
        }
    }

    render() {
        return (
            <SiteLayout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Oss | If-Pilot</title>
                    <script>{`
                    {
            window.intercomSettings = {
                app_id: "wpsqtjpa"
            };
            }`}
                    </script>
                    <script>{`
                    {
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/wpsqtjpa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
            }`}
                    </script>
                </Helmet>
                <div className={styles.smallcontainer}>
                    {this.renderPage()}
                </div>
            </SiteLayout >
        );
    }
}

export default class Bekreft extends React.Component<any, any> {
    render() {
        const BekreftFormComponent = Form.create()(BekreftForm);
        return (<BekreftFormComponent />);
    }
}